<template>
  <div>
    <slot>
      {{ requestState }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: ''
    }
  },
  computed: {
    requestState: function() {
      if (this.state === 'SENT') {
        return 'Inviata';
      }

      if (this.state === 'DELETED') {
        return 'Annullata';
      }

      if (this.state === 'TAKING CHARGE') {
        return 'Presa in carico';
      }

      if (this.state === 'EMITTED') {
        return 'Evasa';
      }

      if (this.state === 'low') {
        return 'Bassa';
      }

      if (this.state === 'medium') {
        return 'Media';
      }

      if (this.state === 'high') {
        return 'Elevata';
      }

      return this.state;
    }
  }
};
</script>
